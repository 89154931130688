.top-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    margin: 0;
    padding: 0;
}
.nav-icon {
    height: 28px;
    width: 28px;
}
#_menuExit {
    position: absolute;
    top: 26px;
    right: 26px;
}
.nav-box {
    padding-inline: 2%;
    padding-block: 16px;
}
.tron-theme .nav-box, .tron-theme .nav-box:start {
    background: #090540;
}
.color-theme .nav-box {
    background: #F6EFEE;
}
.color-theme .nav-box:start {
    background: #F6EFEE;
}
.nav-list {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-content: space-between;
    justify-content: space-between;
    -webkit-align-content: space-between;
}
.nav-list_cluster {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 48px;
}
.nav-list li {
    list-style: none;
}
.nav-list li a, .nav-list li {
    font-size: 32px;
    text-decoration: none;
}
.name {
    font-size: 44px !important;
}
.tron-theme .nav-list li a {
    font-family: 'Array-Regular', sans-serif;
    color: #C063E8;
    text-shadow: 2px 2px #E02B32;
}
.tron-theme .nav-list li a:hover {
    color: rgba(248,217,18,1);
}
.tron-theme .nav-list li a:active {
    color: #E02B32;
    text-shadow: 2px 2px rgba(248,217,18,1);
}
.tron-theme .name {
    font-family: 'Array-Semibold', sans-serif;
    color: #C063E8;
    text-shadow: 2px 2px #E02B32;
}
.color-theme .nav-list li a {
    font-family: 'Fredoka', sans-serif;
    font-weight: 600;
}
.color-theme .name {
    font-family: 'Fredoka', sans-serif;
    position: relative;
    height: 54px;
    font-weight: 600;
}
.color-theme .name_normal {
    position:absolute;
    top:0;
    left:0;
    color: #F879AA;
}
.color-theme .name_outline {
    position:absolute;
    top:0;
    left:0;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #D96A95;
}
.color-theme .nav-projects {
    color: #FF4000;
}
.color-theme .nav-projects:hover {
    color: #AB340E;
}
.color-theme .nav-resume {
    color: #9B7EDE;
}
.color-theme .nav-resume:hover {
    color: #6D5A99;
}
.color-theme .nav-fun {
    color: #70B8FF;
}
.color-theme .nav-fun:hover {
    color: #5C92C9;
}

@media screen and (max-width: 992px) {
    .top-bar {
        display: flex;
        justify-content: flex-end;
        top: 0;
        width: 100vw;
    }
    .hamburglar {
        height: 28px;
        width: 28px;
        margin-right: 22px;
        margin-top: 22px;
        padding: 4px;
        border-radius: 3px;
    }
    .tron-theme .hamburglar {
        background-color: #2c2957;
        border: 2px solid #C063E8;
    }
    .color-theme .hamburglar {
        background-color: #F6EFEE;
        border: 2px solid #F879AA;
    }
    .nav-box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vh;
        z-index: 101;
    }
    .tron-theme .nav-box {
        background-color: #2c2957;
    }
    .nav-list_cluster {
        flex-direction: column;
    }
    .nav-list {
        margin-block: 10px;
        flex-direction: column;
        gap: 10px;
    }
    .name, .name_normal, .name_outline {
        display: none;
    }
    .nav-list li {
        width: auto;
    }
    .tron-theme .nav-list li a {
        font-size: 34px;
        text-shadow: 2px 2px #E02B32;
    }
    .color-theme .nav-list li a {
        font-size: 26px;
    }
}